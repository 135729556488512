<template>
  <div class="commonReport">
    <div class="bgf0 p10">
      <div class="p10 bgff mainBox">
        <el-tabs v-model="activeName">
          <el-tab-pane label="Slow Setting" name="setting">
            <div class="mainBox">
              <el-form ref="postData" :model="postData" :rules="rules" size="mini">
                <el-card shadow="hover" class="mb10">
                  <el-row>
                    <el-col :span="12">
                      <el-form-item
                        label="rejection check hour"
                        label-width="190px"
                        prop="checkHour"
                      >
                        <el-input
                          v-model.number="postData.checkHour"
                          placeholder="check hour"
                          type="number"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-row>
                    <el-col :span="12">
                      <el-form-item
                        label="ctit check hour"
                        label-width="190px"
                        prop="ctitCheckHour"
                      >
                        <el-input
                          v-model.number="postData.ctitCheckHour"
                          placeholder="check hour"
                          type="number"
                        ></el-input>
                      </el-form-item>
                    </el-col>
                  </el-row>
                  <el-form-item label="rejection check(or)" label-width="190px" class="mb0">
                    <el-card shadow="never" class="mb10">
                      <el-row
                        :gutter="10"
                        v-for="(item, index) in postData.rejectionBeans"
                        :key="index"
                      >
                        <el-col :span="5">
                          <el-form-item label="" label-width="0px" class="mb0">
                            <el-select
                              v-model="item.rejection"
                              placeholder="Please rejection Reason"
                              size="mini"
                              style="width: 100%;"
                            >
                              <el-option
                                v-for="item in options.dataChecksKey"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>
                        <!--                        <el-col :span="5">
                          <el-form-item label="" label-width="0px" class="mb0">
                            <el-select
                              v-model="item.type"
                              placeholder="Please Select type"
                              size="mini"
                              style="width: 100%;"
                            >
                              <el-option
                                v-for="item in options.dataChecksAO"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value"
                              ></el-option>
                            </el-select>
                          </el-form-item>
                        </el-col>-->
                        <el-col :span="5">
                          <el-form-item label="rate(%) >" label-width="100px" class="mb0">
                            <el-input v-model="item.val" placeholder="" />
                          </el-form-item>
                        </el-col>
                        <el-col :span="5">
                          <el-form-item label="and number >" label-width="120px" class="mb0">
                            <el-input v-model="item.number" placeholder="" />
                          </el-form-item>
                        </el-col>
                        <el-col :span="4">
                          <el-form-item label="" label-width="0px" class="mb0">
                            <el-button
                              type="primary"
                              size="mini"
                              v-if="index == 0"
                              @click="dataCheckAdd"
                              >add
                            </el-button>
                            <el-button
                              type="danger"
                              size="mini"
                              v-if="index !== 0"
                              @click="dataCheckDel(index)"
                              >delete
                            </el-button>
                          </el-form-item>
                        </el-col>
                      </el-row>
                    </el-card>
                  </el-form-item>
                  <div align="center">
                    <el-button type="primary" size="mini" @click="save('postData')">save</el-button>
                  </div>
                </el-card>
              </el-form>
            </div>
          </el-tab-pane>
          <el-tab-pane label="Slow Offer Sub" name="offerSub">
            <el-table
              v-loading="loading.list"
              ref="tableRef"
              style="width:100%;min-height: 70vmin;"
              stripe
              border
              :data="list"
              highlight-current-row
              aria-setsize="mini"
              size="mini"
              class="mb10"
            >
              <el-table-column label="offerId -> subId" prop="offerId" align="center">
                <template slot-scope="scope">
                  {{ scope.row }}
                </template>
              </el-table-column>
            </el-table>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>
  </div>
</template>
<script>
  import { subCloseGetOne, subCloseUpdate, subCloseOfferSubRedis } from '@/api/setting';

  export default {
    data() {
      return {
        list: [],
        postData: {
          checkHour: 1,
          ctitCheckHour: 6,
          rejectionBeans: [
            {
              rejection: '',
              type: '',
              val: '',
              number: '',
            },
          ],
          autoAction: [],
          autoStart: 0,
        },
        activeName: 'setting',
        autoStartCheckbox: false,
        rules: {
          checkHour: [
            {
              required: true,
              message: 'Please enter check hour',
              trigger: 'blur',
            },
          ],
          ctitCheckHour: [
            {
              required: true,
              message: 'Please enter ctit check hour',
              trigger: 'blur',
            },
          ],
        },
        loading: {
          save: false,
        },
        options: {
          dataChecksKey: [
            { label: 'timestamp_anomalies', value: 'timestamp_anomalies' },
            { label: 'device_emulators', value: 'device_emulators' },
            { label: 'bayesian_network', value: 'bayesian_network' },
            { label: 'click_flood', value: 'click_flood' },
            { label: 'ai_layer', value: 'ai_layer' },
            { label: 'other', value: 'other' },
          ],
          dataChecksAO: [
            { label: 'number', value: 'number' },
            { label: 'rate(%)', value: 'rate' },
          ],
        },
      };
    },
    mounted() {
      this.subCloseGet();
      this.subCloseOfferSubRedis();
    },
    methods: {
      subCloseGet() {
        subCloseGetOne().then((res) => {
          if (res.code == 200) {
            if (
              res.result != null &&
              res.result.rejectionBeans != null &&
              res.result.rejectionBeans.length == 0
            ) {
              res.result.rejectionBeans = [
                {
                  rejection: '',
                  type: '',
                  val: '',
                  number: '',
                },
              ];
            }
            this.postData = res.result;
          }
        });
      },
      subCloseOfferSubRedis() {
        subCloseOfferSubRedis().then((res) => {
          if (res.code == 200) {
            this.list = res.result;
          }
        });
      },
      // 添加 data check(and) item
      dataCheckAdd() {
        const dataCheckObj = {
          rejection: '',
          type: '',
          val: '',
          number: '',
        };
        this.postData.rejectionBeans.push(dataCheckObj);
      },
      // 删除 data check(and) item
      dataCheckDel(index) {
        this.postData.rejectionBeans.splice(index, 1);
      },
      // 保存数据
      save(formName) {
        this.$refs[formName].validate((valid) => {
          if (!valid) return;
          this.loading.save = true;
          // 采用深层拷贝
          let query = JSON.parse(JSON.stringify(this.postData));
          subCloseUpdate(query)
            .then((res) => {
              if (res.code == 200) {
                console.log(res);
                this.$message({
                  message: '更新成功',
                  type: 'success',
                });
                this.$router.push({
                  path: '/setting/subCloseSetting',
                });
              } else {
                this.loading.save = false;
                this.$message.error('更新失败！' + res.message);
              }
            })
            .catch((error) => {
              console.log(error);
              this.loading.save = false;
              this.$message.error('更新失败！');
            });
        });
      },
    },
  };
</script>
<style lang="scss" scoped>
  .cor337ab7 {
    color: #337ab7;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
</style>
